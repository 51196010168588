var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('the-layout-portal', [_vm.isLoading ? _c('v-container', [_c('v-skeleton-loader', {
    attrs: {
      "type": "heading, actions, heading, table-row, list-item-two-line@4"
    }
  })], 1) : _c('v-container', [_c('m-header', {
    attrs: {
      "title": _vm.title,
      "breadCrumbs": _vm.breadCrumbs
    }
  }), _c('fleet-detail', {
    attrs: {
      "value": _vm.fleet,
      "isLoading": _vm.isLoading,
      "vehicles": _vm.vehicles
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }