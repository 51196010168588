var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('m-header', {
    attrs: {
      "title": _vm.value.companyName,
      "breadCrumbs": _vm.breadCrumbs,
      "actions": _vm.actions
    },
    on: {
      "actionClicked": function actionClicked($event) {
        return $event.exec();
      },
      "alertClicked": function alertClicked($event) {
        return $event.exec();
      }
    }
  }), _c('v-divider', {
    staticClass: "mt-2 mb-n4"
  }), _c('m-detail-view-grid', {
    scopedSlots: _vm._u([{
      key: "details",
      fn: function fn() {
        return [_c('company-detail-table', {
          attrs: {
            "value": _vm.value
          }
        })];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function fn() {
        return [_c('m-action-list', {
          attrs: {
            "actions": _vm.bottomActions
          },
          on: {
            "actionClicked": function actionClicked($event) {
              return $event.exec();
            }
          }
        })];
      },
      proxy: true
    }])
  }, [_c('update-form', {
    attrs: {
      "formable": _vm.formable,
      "customConfigKey": 'groupId',
      "loadCustomConfig": _vm.loadCustomConfig,
      "containButtons": false,
      "customCategoryNamePath": "company.companyGroup",
      "partnerId": _vm.value.partnerId
    },
    on: {
      "input": function input($event) {
        return _vm.value.map($event);
      }
    },
    model: {
      value: _vm.value,
      callback: function callback($$v) {
        _vm.value = $$v;
      },
      expression: "value"
    }
  })], 1), _vm.isDeleteDialogActive && _vm.value ? _c('confirm-action-dialog', {
    attrs: {
      "isDialogActive": _vm.isDeleteDialogActive,
      "fullscreen": _vm.$vuetify.breakpoint.smAndDown,
      "title": _vm.$t('partner.PartnerReportDetailChangeProgress.confirmDeleteTitle'),
      "rightColor": 'error'
    },
    on: {
      "update:isDialogActive": function updateIsDialogActive($event) {
        _vm.isDeleteDialogActive = $event;
      },
      "update:is-dialog-active": function updateIsDialogActive($event) {
        _vm.isDeleteDialogActive = $event;
      },
      "close": function close($event) {
        _vm.isDeleteDialogActive = false;
      },
      "leftClick": function leftClick($event) {
        _vm.isDeleteDialogActive = false;
      },
      "rightClick": _vm.onDelete
    }
  }, [_c('refs-company', {
    attrs: {
      "item": _vm.value
    }
  })], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }