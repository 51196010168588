var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-form', {
    model: {
      value: _vm.isValid,
      callback: function callback($$v) {
        _vm.isValid = $$v;
      },
      expression: "isValid"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": _vm.$t('objects.handover.company.name'),
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.value.companyName,
      callback: function callback($$v) {
        _vm.$set(_vm.value, "companyName", $$v);
      },
      expression: "value.companyName"
    }
  }), _c('v-autocomplete', {
    attrs: {
      "items": _vm.items,
      "label": _vm.$t('objects.handover.company.type'),
      "outlined": "",
      "dense": ""
    },
    scopedSlots: _vm._u([{
      key: "selection",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_vm._v(" " + _vm._s(_vm.$t("enums.HandoverCompanyServiceEnum.".concat(item))) + " ")];
      }
    }, {
      key: "item",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_vm._v(" " + _vm._s(_vm.$t("enums.HandoverCompanyServiceEnum.".concat(item))) + " ")];
      }
    }]),
    model: {
      value: _vm.value.companyType,
      callback: function callback($$v) {
        _vm.$set(_vm.value, "companyType", $$v);
      },
      expression: "value.companyType"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": _vm.$t('objects.handover.company.email'),
      "rules": _vm.isEmailRule,
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.value.email,
      callback: function callback($$v) {
        _vm.$set(_vm.value, "email", $$v);
      },
      expression: "value.email"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": _vm.$t('objects.handover.company.phone'),
      "rules": _vm.isPhoneRule,
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.value.phone,
      callback: function callback($$v) {
        _vm.$set(_vm.value, "phone", $$v);
      },
      expression: "value.phone"
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }