import { render, staticRenderFns } from "./CustomProjectTable.vue?vue&type=template&id=9c68549e&"
import script from "./CustomProjectTable.vue?vue&type=script&lang=ts&"
export * from "./CustomProjectTable.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VChip } from 'vuetify/lib/components/VChip';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VChip,VRow})
