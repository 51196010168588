var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [!_vm.hideButton ? _c('v-btn', {
    attrs: {
      "elevation": 0
    },
    on: {
      "click": _vm.open
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("create")) + " ")]) : _vm._e(), _vm.isDialogActive ? _c('confirm-action-dialog', {
    attrs: {
      "width": "1050",
      "persistent": true,
      "supressKeyboardActions": true,
      "title": _vm.$t('company.createCompany'),
      "isDialogActive": _vm.isDialogActive,
      "rightText": _vm.$t('create'),
      "loading": _vm.isLoading,
      "fullscreen": _vm.fullscreen,
      "rightLoading": _vm.isLoading,
      "rightDisabled": !_vm.isFormValid
    },
    on: {
      "update:isDialogActive": function updateIsDialogActive($event) {
        _vm.isDialogActive = $event;
      },
      "update:is-dialog-active": function updateIsDialogActive($event) {
        _vm.isDialogActive = $event;
      },
      "leftClick": _vm.close,
      "close": _vm.close,
      "rightClick": _vm.onRightClick
    }
  }, [_c('v-form', {
    model: {
      value: _vm.isFormValid,
      callback: function callback($$v) {
        _vm.isFormValid = $$v;
      },
      expression: "isFormValid"
    }
  }, [_c('v-row', {
    staticClass: "pt-3"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "7"
    }
  }, [_c('v-text-field', {
    ref: "companyNameField",
    attrs: {
      "rules": _vm.requiredRule,
      "label": _vm.$t('objects.company.companyName') + ' *',
      "outlined": ""
    },
    model: {
      value: _vm.company.companyName,
      callback: function callback($$v) {
        _vm.$set(_vm.company, "companyName", $$v);
      },
      expression: "company.companyName"
    }
  }), _c('custom-field-boolean', {
    attrs: {
      "customField": {
        label: _vm.$t('objects.company.isFleet')
      }
    },
    model: {
      value: _vm.company.isFleet,
      callback: function callback($$v) {
        _vm.$set(_vm.company, "isFleet", $$v);
      },
      expression: "company.isFleet"
    }
  }), _c('v-combobox', {
    attrs: {
      "items": Object.values(_vm.CompanyServiceEnum),
      "label": _vm.$t('objects.company.services'),
      "auto-select-first": "",
      "outlined": "",
      "multiple": "",
      "clearable": ""
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_vm._v(" " + _vm._s(_vm.$t("enums.CompanyServiceEnum.".concat(item))) + " ")];
      }
    }, {
      key: "selection",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_vm._v(" " + _vm._s(_vm.$t("enums.CompanyServiceEnum.".concat(item))) + " ")];
      }
    }], null, false, 979737020),
    model: {
      value: _vm.company.services,
      callback: function callback($$v) {
        _vm.$set(_vm.company, "services", $$v);
      },
      expression: "company.services"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": _vm.$t('objects.company.taxnumber'),
      "outlined": ""
    },
    model: {
      value: _vm.company.taxnumber,
      callback: function callback($$v) {
        _vm.$set(_vm.company, "taxnumber", $$v);
      },
      expression: "company.taxnumber"
    }
  }), _c('v-autocomplete', {
    attrs: {
      "item-value": "id",
      "items": _vm.groups,
      "label": _vm.$t('objects.company.groupId'),
      "auto-select-first": "",
      "outlined": "",
      "clearable": "",
      "loading": _vm.isLoadingCompanyGroups
    },
    on: {
      "click": _vm.refreshCompanyGroups
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_vm._v(" " + _vm._s(item.title) + " ")];
      }
    }, {
      key: "selection",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_vm._v(" " + _vm._s(item.title) + " ")];
      }
    }], null, false, 2714690748),
    model: {
      value: _vm.company.groupId,
      callback: function callback($$v) {
        _vm.$set(_vm.company, "groupId", $$v);
      },
      expression: "company.groupId"
    }
  }), _c('div', {
    staticClass: "mt-n7",
    staticStyle: {
      "display": "flex"
    }
  }, [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "x-small": "",
      "text": "",
      "elevation": 0,
      "loading": _vm.isLoadingCompanyGroups
    },
    on: {
      "click": _vm.refreshCompanyGroups
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("common.verbs.refresh")) + " ")]), _c('v-btn', {
    attrs: {
      "x-small": "",
      "text": "",
      "elevation": 0
    },
    on: {
      "click": _vm.goToCompanyGroupCustomView
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("common.verbs.view")) + " ")])], 1), _vm.companyGroup && _vm.companyGroup.configuration && _vm.companyGroup.configuration.customFieldConfig && _vm.companyGroup.configuration.customFieldConfig.length ? _c('div', [_vm.isLoadingCustomFieldValues ? _c('v-progress-linear', {
    attrs: {
      "indeterminate": ""
    }
  }) : _vm._e(), _c('custom-field-list-form', {
    staticClass: "mt-6",
    attrs: {
      "customFieldConfig": _vm.companyGroup.configuration.customFieldConfig
    },
    model: {
      value: _vm.company.values,
      callback: function callback($$v) {
        _vm.$set(_vm.company, "values", $$v);
      },
      expression: "company.values"
    }
  }), _c('debug', [_vm._v(" company.values: " + _vm._s(_vm.company.values)), _c('br'), _vm._v(" company.groupId: " + _vm._s(_vm.company.groupId)), _c('br'), _vm._v(" companyGroup: " + _vm._s(_vm.companyGroup)), _c('br')])], 1) : _vm._e()], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "5"
    }
  }, [_vm.company.contact ? _c('div', [_c('v-text-field', {
    attrs: {
      "rules": _vm.emailRule,
      "label": _vm.$t('objects.company.contact.email'),
      "outlined": ""
    },
    model: {
      value: _vm.company.contact.email,
      callback: function callback($$v) {
        _vm.$set(_vm.company.contact, "email", $$v);
      },
      expression: "company.contact.email"
    }
  }), _c('v-text-field', {
    attrs: {
      "rules": _vm.phoneRule,
      "label": _vm.$t('objects.company.contact.phone'),
      "outlined": ""
    },
    model: {
      value: _vm.company.contact.phone,
      callback: function callback($$v) {
        _vm.$set(_vm.company.contact, "phone", $$v);
      },
      expression: "company.contact.phone"
    }
  })], 1) : _vm._e(), _vm.company.addresses ? _c('div', [_c('v-subheader', [_vm._v(" " + _vm._s(_vm.$t("objects.address.address")) + " #" + _vm._s(1) + " ")]), _c('v-text-field', {
    attrs: {
      "rules": _vm.requiredRule,
      "label": _vm.$t('objects.address.street') + ' *',
      "outlined": ""
    },
    model: {
      value: _vm.company.addresses[0].street,
      callback: function callback($$v) {
        _vm.$set(_vm.company.addresses[0], "street", $$v);
      },
      expression: "company.addresses[0].street"
    }
  }), _c('v-text-field', {
    attrs: {
      "rules": _vm.requiredRule,
      "label": _vm.$t('objects.address.zip') + ' *',
      "outlined": ""
    },
    model: {
      value: _vm.company.addresses[0].zip,
      callback: function callback($$v) {
        _vm.$set(_vm.company.addresses[0], "zip", $$v);
      },
      expression: "company.addresses[0].zip"
    }
  }), _c('v-text-field', {
    attrs: {
      "rules": _vm.requiredRule,
      "label": _vm.$t('objects.address.city') + ' *',
      "outlined": ""
    },
    model: {
      value: _vm.company.addresses[0].city,
      callback: function callback($$v) {
        _vm.$set(_vm.company.addresses[0], "city", $$v);
      },
      expression: "company.addresses[0].city"
    }
  }), _c('v-text-field', {
    attrs: {
      "rules": _vm.requiredRule,
      "label": _vm.$t('objects.address.state') + ' *',
      "outlined": ""
    },
    model: {
      value: _vm.company.addresses[0].state,
      callback: function callback($$v) {
        _vm.$set(_vm.company.addresses[0], "state", $$v);
      },
      expression: "company.addresses[0].state"
    }
  }), _c('v-autocomplete', {
    attrs: {
      "items": Object.values(_vm.CountryCodeEnum),
      "label": _vm.$t('objects.address.countryCode') + ' *',
      "rules": _vm.requiredRule,
      "auto-select-first": "",
      "outlined": ""
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [_vm._v(" " + _vm._s(item) + " ")];
      }
    }, {
      key: "selection",
      fn: function fn(_ref6) {
        var item = _ref6.item;
        return [_vm._v(" " + _vm._s(item) + " ")];
      }
    }], null, false, 1731429820),
    model: {
      value: _vm.company.addresses[0].countryCode,
      callback: function callback($$v) {
        _vm.$set(_vm.company.addresses[0], "countryCode", $$v);
      },
      expression: "company.addresses[0].countryCode"
    }
  }), _c('v-autocomplete', {
    attrs: {
      "items": Object.values(_vm.CompanyAddressTypeEnum),
      "label": _vm.$t('objects.address.type') + ' *',
      "rules": _vm.requiredRule,
      "auto-select-first": "",
      "outlined": "",
      "multiple": ""
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function fn(_ref7) {
        var item = _ref7.item;
        return [_vm._v(" " + _vm._s(_vm.$t("enums.CompanyAddressTypeEnum.".concat(item))) + " ")];
      }
    }, {
      key: "selection",
      fn: function fn(_ref8) {
        var item = _ref8.item;
        return [_vm._v(" " + _vm._s(_vm.$t("enums.CompanyAddressTypeEnum.".concat(item))) + " ")];
      }
    }], null, false, 3014544412),
    model: {
      value: _vm.company.addresses[0].type,
      callback: function callback($$v) {
        _vm.$set(_vm.company.addresses[0], "type", $$v);
      },
      expression: "company.addresses[0].type"
    }
  }), _vm._l(_vm.company.addresses.slice(1, _vm.company.addresses.length), function (address, index) {
    return _c('div', {
      key: index
    }, [_c('v-subheader', [_vm._v(" " + _vm._s(_vm.$t("objects.address.address")) + " #" + _vm._s(index + 2) + " "), _c('v-spacer'), _c('v-btn', {
      attrs: {
        "text": "",
        "x-small": ""
      },
      on: {
        "click": function click($event) {
          return _vm.popAddress(index + 1);
        }
      }
    }, [_vm._v(" " + _vm._s(_vm.$t("company.removeAddress")) + " ")])], 1), _c('v-text-field', {
      attrs: {
        "label": _vm.$t('objects.address.street'),
        "outlined": ""
      },
      model: {
        value: address.street,
        callback: function callback($$v) {
          _vm.$set(address, "street", $$v);
        },
        expression: "address.street"
      }
    }), _c('v-text-field', {
      attrs: {
        "label": _vm.$t('objects.address.zip'),
        "outlined": ""
      },
      model: {
        value: address.zip,
        callback: function callback($$v) {
          _vm.$set(address, "zip", $$v);
        },
        expression: "address.zip"
      }
    }), _c('v-text-field', {
      attrs: {
        "label": _vm.$t('objects.address.city'),
        "outlined": ""
      },
      model: {
        value: address.city,
        callback: function callback($$v) {
          _vm.$set(address, "city", $$v);
        },
        expression: "address.city"
      }
    }), _c('v-text-field', {
      attrs: {
        "label": _vm.$t('objects.address.state'),
        "outlined": ""
      },
      model: {
        value: address.state,
        callback: function callback($$v) {
          _vm.$set(address, "state", $$v);
        },
        expression: "address.state"
      }
    }), _c('v-autocomplete', {
      attrs: {
        "items": Object.values(_vm.CountryCodeEnum),
        "label": _vm.$t('objects.address.countryCode'),
        "auto-select-first": "",
        "outlined": ""
      },
      scopedSlots: _vm._u([{
        key: "item",
        fn: function fn(_ref9) {
          var item = _ref9.item;
          return [_vm._v(" " + _vm._s(item) + " ")];
        }
      }, {
        key: "selection",
        fn: function fn(_ref10) {
          var item = _ref10.item;
          return [_vm._v(" " + _vm._s(item) + " ")];
        }
      }], null, true),
      model: {
        value: address.countryCode,
        callback: function callback($$v) {
          _vm.$set(address, "countryCode", $$v);
        },
        expression: "address.countryCode"
      }
    }), _c('v-combobox', {
      attrs: {
        "items": Object.values(_vm.CompanyAddressTypeEnum),
        "label": _vm.$t('objects.address.type'),
        "auto-select-first": "",
        "outlined": "",
        "multiple": "",
        "clearable": ""
      },
      scopedSlots: _vm._u([{
        key: "item",
        fn: function fn(_ref11) {
          var item = _ref11.item;
          return [_vm._v(" " + _vm._s(_vm.$t("enums.CompanyAddressTypeEnum.".concat(item))) + " ")];
        }
      }, {
        key: "selection",
        fn: function fn(_ref12) {
          var item = _ref12.item;
          return [_vm._v(" " + _vm._s(_vm.$t("enums.CompanyAddressTypeEnum.".concat(item))) + " ")];
        }
      }], null, true),
      model: {
        value: address.type,
        callback: function callback($$v) {
          _vm.$set(address, "type", $$v);
        },
        expression: "address.type"
      }
    })], 1);
  }), _c('v-card', {
    staticClass: "d-flex mb-8",
    attrs: {
      "outlined": ""
    },
    on: {
      "click": _vm.addAddress
    }
  }, [_c('v-list-item', {
    staticStyle: {
      "height": "56px"
    }
  }, [_c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm.$t("company.addAddress")) + " ")])], 1)], 1)], 2) : _vm._e()])], 1), _c('debug', [_vm._v(" " + _vm._s(_vm.company) + " ")])], 1)], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }