var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('m-detail-table-formable', {
    ref: "table",
    attrs: {
      "title": _vm.$t('about'),
      "item": _vm.value,
      "formable": _vm.formable,
      "outlined": true,
      "omit": ['id', 'company', 'partnerId', '.lastModified', 'values', 'assignees', 'groupId'],
      "showCustomFields": false,
      "onDetail": _vm.onDetail
    },
    scopedSlots: _vm._u([{
      key: "item.addresses",
      fn: function fn(_ref) {
        var item = _ref.item,
            index = _ref.index;
        return [_c('v-hover', {
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref2) {
              var hover = _ref2.hover;
              return [_c('div', {
                staticStyle: {
                  "display": "flex",
                  "align-items": "center"
                }
              }, [_c('td', [item.addresses && item.addresses[index] ? _c('v-list-item', {
                staticClass: "pa-0",
                on: {
                  "click": function click($event) {
                    return _vm.copyAddressToClipboard(item.addresses[index]);
                  }
                }
              }, [item.addresses && item.addresses[index] ? _c('v-list-item-content', [_c('v-list-item-title', [_vm._v(" " + _vm._s(_vm._f("getFlagEmoji")(item.addresses[index].countryCode)) + " " + _vm._s(item.addresses[index].street) + " ")]), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(item.addresses[index].zip) + " " + _vm._s(item.addresses[index].city) + " ")]), _c('v-list-item-subtitle', _vm._l(item.addresses[index].type, function (type) {
                return _c('v-chip', {
                  key: type + Math.random(),
                  staticClass: "mr-1",
                  attrs: {
                    "small": ""
                  }
                }, [_vm._v(" " + _vm._s(_vm.$t("enums.CompanyAddressTypeEnum.".concat(type))) + " ")]);
              }), 1)], 1) : _vm._e()], 1) : _vm._e()], 1), _c('td', [hover ? _c('v-btn', {
                attrs: {
                  "icon": "",
                  "small": ""
                },
                on: {
                  "click": function click($event) {
                    $event.stopPropagation();
                    return _vm.copyAddressToClipboard(item.addresses[index]);
                  }
                }
              }, [_c('v-icon', {
                attrs: {
                  "small": ""
                }
              }, [_vm._v("mdi-content-copy")])], 1) : _vm._e()], 1)])];
            }
          }], null, true)
        })];
      }
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }