var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('paginated-table', {
    attrs: {
      "store": _vm.store,
      "headers": _vm.headers,
      "baseQuery": {
        partnerId: _vm.partnerId
      },
      "predefinedFilter": _vm.predefinedFilter
    },
    on: {
      "click:row": _vm.setSelectedItem
    },
    scopedSlots: _vm._u([{
      key: "bodyActions",
      fn: function fn() {
        return [_vm.can(_vm.ActionEnum.CREATE, _vm.ResourceEnum.FLEET) ? _c('create-fleet-dialog', {
          attrs: {
            "partnerId": _vm.partnerId
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref) {
              var on = _ref.on;
              return [_c('v-btn', {
                attrs: {
                  "color": "primary",
                  "elevation": 0
                },
                on: {
                  "click": on
                }
              }, [_vm._v(" " + _vm._s(_vm.$t("create")) + " ")])];
            }
          }], null, false, 4072416835)
        }) : _vm._e()];
      },
      proxy: true
    }, {
      key: "item.assignees",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [item.assignees.length ? _c('assignee-preview', {
          attrs: {
            "value": item.assignees,
            "amount": 3
          }
        }) : _c('span', [_vm._v("-")])];
      }
    }, {
      key: "item.timestamp.created",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_vm._v(" " + _vm._s(item.timestamp.createdReadable) + " ")];
      }
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }