var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('m-header', {
    attrs: {
      "title": _vm.value.companyName,
      "breadCrumbs": _vm.breadCrumbs,
      "chips": _vm.chips,
      "actions": _vm.actions
    },
    on: {
      "actionClicked": function actionClicked($event) {
        return $event.exec();
      },
      "alertClicked": function alertClicked($event) {
        return $event.exec();
      }
    }
  }), _c('v-divider', {
    staticClass: "mt-2 mb-n4"
  }), _c('m-detail-view-grid', {
    scopedSlots: _vm._u([{
      key: "details",
      fn: function fn() {
        return [_c('company-detail-table', {
          attrs: {
            "value": _vm.value,
            "onDetail": _vm.goToCompanyDetailForm
          }
        }), _c('v-divider', {
          staticClass: "mt-6"
        }), _c('select-assignees', {
          staticClass: "mt-6",
          attrs: {
            "css": "width: 100%",
            "outlined": true,
            "dense": false
          },
          on: {
            "input": _vm.onAssigneesUpdate,
            "added": _vm.onAssigneesAdded,
            "removed": _vm.onAssigneesRemoved
          },
          model: {
            value: _vm.value.assignees,
            callback: function callback($$v) {
              _vm.$set(_vm.value, "assignees", $$v);
            },
            expression: "value.assignees"
          }
        }), _c('v-select', {
          staticClass: "mt-2",
          attrs: {
            "item-value": "id",
            "items": _vm.groups,
            "label": _vm.$t('objects.company.groupId'),
            "outlined": "",
            "clearable": "",
            "loading": _vm.isLoadingCompanyGroup
          },
          on: {
            "change": _vm.saveGroup,
            "click": _vm.refreshCompanyGroups
          },
          scopedSlots: _vm._u([{
            key: "append",
            fn: function fn() {
              return [_c('company-group-create-dialog', {
                attrs: {
                  "partnerId": _vm.value.partnerId
                },
                on: {
                  "created": function created(companyGroup) {
                    return _vm.value.groupId = companyGroup.id;
                  }
                },
                scopedSlots: _vm._u([{
                  key: "activator",
                  fn: function fn(_ref) {
                    var on = _ref.on;
                    return [_c('v-btn', {
                      staticStyle: {
                        "position": "relative",
                        "top": "-6px"
                      },
                      attrs: {
                        "icon": "",
                        "loading": _vm.isLoadingCompanyGroups
                      },
                      on: {
                        "click": function click($event) {
                          $event.stopPropagation();
                          return on.apply(null, arguments);
                        }
                      }
                    }, [_c('v-icon', [_vm._v("mdi-plus")])], 1)];
                  }
                }])
              })];
            },
            proxy: true
          }, {
            key: "item",
            fn: function fn(_ref2) {
              var item = _ref2.item;
              return [_vm._v(" " + _vm._s(item.title) + " ")];
            }
          }, {
            key: "selection",
            fn: function fn(_ref3) {
              var item = _ref3.item;
              return [_vm._v(" " + _vm._s(item.title) + " ")];
            }
          }]),
          model: {
            value: _vm.value.groupId,
            callback: function callback($$v) {
              _vm.$set(_vm.value, "groupId", $$v);
            },
            expression: "value.groupId"
          }
        }), _c('div', {
          staticClass: "mt-n7",
          staticStyle: {
            "display": "flex"
          }
        }, [_c('v-spacer'), _c('v-btn', {
          attrs: {
            "x-small": "",
            "text": "",
            "elevation": 0,
            "loading": _vm.isLoadingCompanyGroups
          },
          on: {
            "click": _vm.refreshCompanyGroups
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("common.verbs.refresh")) + " ")]), _c('v-btn', {
          attrs: {
            "x-small": "",
            "text": "",
            "elevation": 0
          },
          on: {
            "click": _vm.goToCompanyGroupCustomView
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("common.verbs.view")) + " ")])], 1), _vm.companyGroup && _vm.companyGroup.configuration && _vm.companyGroup.configuration.customFieldConfig && _vm.companyGroup.configuration.customFieldConfig.length ? _c('div', [_vm.isLoadingCustomFieldValues ? _c('v-progress-linear', {
          attrs: {
            "indeterminate": ""
          }
        }) : _c('v-divider'), _c('custom-field-list-form', {
          staticClass: "mt-6",
          attrs: {
            "customFieldConfig": _vm.companyGroup.configuration.customFieldConfig
          },
          on: {
            "change": _vm.debounceSaveCustomFields
          },
          model: {
            value: _vm.value.values,
            callback: function callback($$v) {
              _vm.$set(_vm.value, "values", $$v);
            },
            expression: "value.values"
          }
        })], 1) : _vm._e()];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function fn() {
        return [_c('v-divider', {
          staticClass: "mt-6"
        }), _c('m-action-list', {
          attrs: {
            "actions": _vm.bottomActions
          },
          on: {
            "actionClicked": function actionClicked($event) {
              return $event.exec();
            }
          }
        })];
      },
      proxy: true
    }])
  }, [_vm.coordinates ? _c('v-row', [_c('v-col', [_c('partner-map', {
    staticClass: "z-index: 0; !important;",
    attrs: {
      "coordinates": _vm.coordinates,
      "height": 265,
      "colour": _vm.color
    }
  })], 1)], 1) : _vm._e(), _c('v-divider', {
    staticClass: "mt-2"
  }), _c('activity-card', {
    attrs: {
      "partner": _vm.partner,
      "source": _vm.source,
      "config": {
        comment: true,
        mail: false
      }
    }
  })], 1), _vm.isDeleteDialogActive && _vm.value ? _c('confirm-action-dialog', {
    attrs: {
      "isDialogActive": _vm.isDeleteDialogActive,
      "fullscreen": _vm.$vuetify.breakpoint.smAndDown,
      "title": _vm.$t('partner.PartnerReportDetailChangeProgress.confirmDeleteTitle'),
      "rightColor": 'error'
    },
    on: {
      "update:isDialogActive": function updateIsDialogActive($event) {
        _vm.isDeleteDialogActive = $event;
      },
      "update:is-dialog-active": function updateIsDialogActive($event) {
        _vm.isDeleteDialogActive = $event;
      },
      "close": function close($event) {
        _vm.isDeleteDialogActive = false;
      },
      "leftClick": function leftClick($event) {
        _vm.isDeleteDialogActive = false;
      },
      "rightClick": _vm.onDelete
    }
  }, [_c('refs-company', {
    attrs: {
      "item": _vm.value
    }
  })], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }