var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', [_c('v-col', {
    staticClass: "hidden-xs-only",
    attrs: {
      "cols": "12",
      "md": "9"
    }
  }, [_c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-card-title', {
    staticClass: "mx-n4"
  }, [_vm._t("titleAction"), _vm.showCalendar ? _c('v-btn', {
    attrs: {
      "outlined": "",
      "small": "",
      "color": "info"
    },
    on: {
      "click": _vm.resetFocus
    }
  }, [_vm._v(" " + _vm._s(_vm.i18n["today"]) + " ")]) : _vm._e(), _vm.showCalendar ? _c('v-btn', {
    staticClass: "ml-2",
    attrs: {
      "icon": "",
      "loading": _vm.loading
    },
    on: {
      "click": _vm.prevMonth
    }
  }, [_c('v-icon', [_vm._v("mdi-chevron-left")])], 1) : _vm._e(), _vm.showCalendar ? _c('v-btn', {
    staticClass: "mr-2",
    attrs: {
      "icon": "",
      "loading": _vm.loading
    },
    on: {
      "click": _vm.nextMonth
    }
  }, [_c('v-icon', [_vm._v("mdi-chevron-right")])], 1) : _vm._e(), _vm.showCalendar ? _c('span', {
    domProps: {
      "textContent": _vm._s(_vm.calenderTitle)
    }
  }) : _vm._e(), !_vm.showCalendar ? _c('analytics-date-range-selector-dialog', {
    attrs: {
      "showFuture": true,
      "showSuggested": true,
      "hideSelectedItemText": true,
      "dates": _vm.dateRange
    },
    on: {
      "setDate": _vm.setDateRange,
      "clickDateRange": _vm.clickDateSelector
    }
  }) : _vm._e(), _c('v-spacer'), _c('v-menu', {
    attrs: {
      "left": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on,
            attrs = _ref.attrs;
        return [_c('v-btn', _vm._g(_vm._b({
          attrs: {
            "small": "",
            "text": "",
            "color": "info"
          }
        }, 'v-btn', attrs, false), on), [_vm.showCalendar ? _c('span', [_vm._v(" " + _vm._s(_vm.$t("views.fleet.EventCard." + _vm.calendarType)) + " ")]) : _c('span', [_vm._v(" " + _vm._s(_vm.$t("components.booking.PartnerBookingCalendar.table")) + " ")]), _c('v-icon', [_vm._v("mdi-menu-down")])], 1)];
      }
    }])
  }, [_c('v-list', [_c('div', _vm._l(_vm.calendarTypes, function (item, index) {
    return _c('v-list-item', {
      key: index,
      on: {
        "click": function click($event) {
          return _vm.setCalendarType(item);
        }
      }
    }, [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v(_vm._s(_vm.$t("views.fleet.EventCard." + item)))])], 1)], 1);
  }), 1), _vm.headers ? _c('v-list-item', {
    on: {
      "click": _vm.toggleCalendarTable
    }
  }, [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v(" " + _vm._s(_vm.showCalendar ? _vm.i18n["table"] : _vm.i18n["calendar"]) + " ")])], 1)], 1) : _vm._e()], 1)], 1), _vm._t("contextMenuAction")], 2), _c('v-sheet', [_c('v-slide-x-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [_vm.showCalendar ? _c('v-calendar', {
    ref: "calendar",
    style: _vm.calendarStyle,
    attrs: {
      "color": "primary",
      "flat": "",
      "type": _vm.calendarType,
      "weekdays": [1, 2, 3, 4, 5, 6, 0],
      "events": _vm.events,
      "locale": "de-de",
      "category-show-all": _vm.categories && _vm.categories.length,
      "categories": _vm.categories
    },
    on: {
      "click:event": function clickEvent(v) {
        return _vm.clickEvent(v);
      },
      "click:time": function clickTime(v) {
        return _vm.$emit('click:time', v);
      },
      "change": function change(v) {
        return _vm.$emit('change', v);
      },
      "click:more": _vm.onMoreClick,
      "contextmenu:event": function contextmenuEvent(v) {
        return _vm.$emit('contextmenu:event', v);
      },
      "contextmenu:time": function contextmenuTime(v) {
        return _vm.$emit('contextmenu:time', v);
      }
    },
    scopedSlots: _vm._u([{
      key: "event",
      fn: function fn(_ref2) {
        var event = _ref2.event;
        return [_vm._t("event", function () {
          return [_vm._v(" " + _vm._s(event.name) + " ")];
        }, {
          "event": event
        })];
      }
    }], null, true),
    model: {
      value: _vm.focus,
      callback: function callback($$v) {
        _vm.focus = $$v;
      },
      expression: "focus"
    }
  }) : _vm._e()], 1), _c('v-slide-x-transition', {
    attrs: {
      "hide-on-leave": ""
    }
  }, [!_vm.showCalendar ? _c('table-wrapper', {
    attrs: {
      "headers": _vm.headers,
      "allItems": _vm.tableEvents,
      "loading": _vm.loading,
      "items-per-page": 10,
      "sortBy": "start",
      "sortDesc": false,
      "groupBy": _vm.groupBy
    },
    on: {
      "click:row": function clickRow(v) {
        return _vm.$emit('click:row', v);
      }
    },
    scopedSlots: _vm._u([{
      key: "bodyActions",
      fn: function fn() {
        return [_vm._t("bodyActions")];
      },
      proxy: true
    }, _vm._l(_vm.headers, function (header, index) {
      return {
        key: "item.".concat(header.value),
        fn: function fn(_ref3) {
          var item = _ref3.item,
              value = _ref3.value;
          return [_c('span', {
            key: header.value + index
          }, [header.value ? _vm._t("item.".concat(header.value), null, {
            "item": item,
            "value": value
          }) : _vm._e()], 2)];
        }
      };
    }), {
      key: "group.header",
      fn: function fn(_ref4) {
        var groupBy = _ref4.groupBy,
            group = _ref4.group,
            toggle = _ref4.toggle,
            isOpen = _ref4.isOpen,
            items = _ref4.items,
            headers = _ref4.headers,
            remove = _ref4.remove,
            isMobile = _ref4.isMobile;
        return [_vm._t("group.header", null, {
          "groupBy": groupBy,
          "group": group,
          "toggle": toggle,
          "isOpen": isOpen,
          "items": items,
          "headers": headers,
          "remove": remove,
          "isMobile": isMobile
        })];
      }
    }], null, true)
  }) : _vm._e()], 1)], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('debug', [_c('br'), _vm._v(" focus " + _vm._s(_vm.focus)), _c('br'), _vm._v(" upcom " + _vm._s(_vm.eventThreshold)), _c('br')]), _c('v-date-picker', {
    staticClass: "pt-2",
    attrs: {
      "no-title": "",
      "full-width": "",
      "color": "primary",
      "first-day-of-week": "1",
      "locale": _vm.$t('report.datePicker.locale')
    },
    on: {
      "input": _vm.clickDateSelector
    },
    model: {
      value: _vm.eventThreshold,
      callback: function callback($$v) {
        _vm.eventThreshold = $$v;
      },
      expression: "eventThreshold"
    }
  }), _c('v-card', {
    staticClass: "mt-n6 mb-n4",
    attrs: {
      "flat": ""
    }
  }, [_c('v-card-actions', {
    staticClass: "d-flex d-sm-none"
  }, [_vm._t("titleAction")], 2), _c('v-card-title', [_vm._v(" " + _vm._s(_vm.$t("components.fleet.FleetHomeVehicleAppointmentTimeline.upcomingTitle")) + " ")]), _c('v-card-subtitle', [_vm._v(" " + _vm._s(_vm.i18n["after"]) + " " + _vm._s(_vm._f("simpleDoubleDigitDate")(_vm.eventThreshold)) + " ")])], 1), _vm.loading ? _c('v-skeleton-loader', {
    staticClass: "mx-auto",
    attrs: {
      "type": "list-item-three-line, list-item-three-line, list-item-three-line"
    }
  }) : _vm.upcomingEvents.length ? _c('v-list', {
    staticStyle: {
      "height": "calc(100vh - 60px)",
      "max-height": "380px",
      "overflow-y": "auto",
      "overflow-x": "hidden"
    }
  }, _vm._l(_vm.upcomingEvents, function (event, index) {
    return _c('div', {
      key: index + event.name,
      on: {
        "click": function click(clickEvent) {
          return _vm.clickUpcomingEvent(event, clickEvent);
        }
      }
    }, [_vm._t("upcoming", function () {
      return [_c('v-list-item', [_c('v-list-item-content', [_c('v-list-item-subtitle', {
        staticClass: "text-xs"
      }, [_vm._v(" " + _vm._s(_vm._f("detailedDateWithDay")(event.start)) + " "), event.end ? _c('span', [_vm._v(" - " + _vm._s(_vm._f("simpleDoubleDigitDate")(event.end)) + " ")]) : _vm._e(), event.timed ? _c('span', [_vm._v("| " + _vm._s(_vm._f("formatHoursAndMinutes")(new Date(event.start))))]) : _vm._e(), event.timed && event.end ? _c('span', [_vm._v("- " + _vm._s(_vm._f("formatHoursAndMinutes")(new Date(event.end))))]) : _vm._e()]), _c('v-list-item-title', [_vm._v(" " + _vm._s(event.name) + " ")])], 1)], 1)];
    }, {
      "slotScope": {
        item: event
      }
    })], 2);
  }), 0) : _c('v-card', {
    staticClass: "fill-height",
    attrs: {
      "fluid": "",
      "flat": ""
    }
  }, [_c('v-img', {
    attrs: {
      "src": require("@/assets/undraw/undraw_meditation_re_gll0.svg"),
      "max-height": "150px",
      "contain": ""
    }
  }), _c('v-card-text', {
    staticClass: "text-center text-caption"
  }, [_vm._v(" " + _vm._s(_vm.$t("views.fleet.EventCard.noEventsUntilEndOfMonth", {
    month: _vm.thresholdMonthEnd
  })) + " ")])], 1)], 1)], 1), _vm.selectedOpen ? _c('v-menu', {
    attrs: {
      "offset-x": "",
      "left": "",
      "close-on-content-click": false,
      "activator": _vm.selectedElement,
      "max-width": "400"
    },
    model: {
      value: _vm.selectedOpen,
      callback: function callback($$v) {
        _vm.selectedOpen = $$v;
      },
      expression: "selectedOpen"
    }
  }, [_vm._t("preview", function () {
    return [_vm.selectedEvent ? _c('v-card', {
      staticStyle: {
        "min-width": "350px"
      }
    }, [_c('v-card-title', [_c('span', {
      staticClass: "text-truncate",
      staticStyle: {
        "max-width": "290px"
      }
    }, [_vm._v(" " + _vm._s(_vm.selectedEvent.name) + " ")]), _c('v-spacer'), !_vm.hideExpand ? _c('v-btn', {
      attrs: {
        "icon": ""
      },
      on: {
        "click": _vm.openDetail
      }
    }, [_c('v-icon', [_vm._v(" mdi-arrow-expand ")])], 1) : _vm._e(), _c('v-btn', {
      attrs: {
        "icon": ""
      },
      on: {
        "click": function click($event) {
          _vm.selectedOpen = false;
        }
      }
    }, [_c('v-icon', [_vm._v(" mdi-close ")])], 1)], 1), _c('v-card-text', [_c('v-list', [_c('v-list-item', {
      staticClass: "px-0"
    }, [_c('v-list-item-icon', [_c('v-icon', [_vm._v("mdi-clock")])], 1), _c('v-list-item-content', [_c('v-list-item-title', [_vm._v(" " + _vm._s(_vm._f("detailedDateWithDay")(_vm.selectedEvent.start)) + " ")]), _vm.selectedEvent.timed ? _c('v-list-item-subtitle', [_c('span', [_vm._v(_vm._s(_vm._f("formatHoursAndMinutes")(new Date(_vm.selectedEvent.start))))]), _vm.selectedEvent.end ? _c('span', [_vm._v("- " + _vm._s(_vm._f("formatHoursAndMinutes")(new Date(_vm.selectedEvent.end))))]) : _vm._e()]) : _vm._e()], 1)], 1), _vm._t("preview.detail", null, {
      "slotScope": {
        item: _vm.selectedEvent,
        close: function close() {
          return _vm.selectedOpen = false;
        }
      }
    })], 2)], 1)], 1) : _c('v-card', [_c('v-skeleton-loader', {
      attrs: {
        "type": "card"
      }
    })], 1)];
  }, {
    "slotScope": {
      item: _vm.selectedEvent,
      close: function close() {
        return _vm.selectedOpen = false;
      },
      open: _vm.openDetail
    }
  })], 2) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }